import React from "react"
import { Icon,Button } from "@chakra-ui/core"
import { motion } from "framer-motion"

import { useBlobStore } from "../../utils/store"

const MotionButton = motion.custom(Button)

const RandomButton = () => {
  const updateData = useBlobStore(state => state.updateData)
  return (
    <MotionButton
      width="4rem"
      height="4rem"
      borderRadius="full"
      bg="pink.500"
      borderColor="white"
      borderWidth="0.125rem"
      boxShadow="none"
      onClick={updateData}
      transition="none"
      _hover={{ bg: "pink.600" }}
      _active={{ bg: "pink.700" }}
      whileHover={{ scale: 1.1, rotate: "-15deg" }}
      whileTap={{ scale: 0.9, rotate: "360deg" }}
      style={{ touchAction: "manipulation" }}
    >
      <Icon
        name="randomIcon"
        width="2rem"
        height="2rem"
        color="white"
      />
    </MotionButton>
  )
}

export default RandomButton