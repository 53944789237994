import React, { useState } from "react"
import addToMailchimp from "gatsby-plugin-mailchimp"
import { Box, Heading, Text, Stack, Input, Button, Checkbox } from "@chakra-ui/core"

const Newsletter = () => {
  const [GDPRConfirmation, setGDPRConfirmation] = useState(false)
  const [email, setEmail] = useState("")
  const [message, setMessage] = useState("")
  const [hasError, setError] = useState(false)

  const handleSubmit = (evt) => {
    evt.preventDefault()
    if (!GDPRConfirmation) {
      if (email) {
        setError(true)
        setMessage("Please tick the checkbox below to proceed.")
        return
      } else {
        setError(true)
        setMessage("Type in your email address")
        return
      }
    }
    addToMailchimp(email, { "group[4611][2]": "2" })
      .then(({ msg, result }) => {
        if (result !== "success") {
          throw msg
        } else {
          setError(false)
          setMessage(msg)
        }
      })
      .catch((err) => {
        setError(true)
        setMessage(err)
      })
  }

  const handleChange = (evt) => {
    setEmail(evt.target.value)
  }

  const handleOptIn = (evt) => {
    setGDPRConfirmation(evt.target.checked)
  }

  return (
    <Stack spacing="1.25rem">
      <Heading as="h2" fontSize="xl">{"Newsletter"}</Heading>
      <Box>
        <form onSubmit={handleSubmit}>
          <Stack spacing="0.75rem">
            <Input
              isRequired
              placeholder="Your email"
              _placeholder={{
                color: "gray.500",
              }}
              borderWidth="0.125rem"
              borderColor="gray.300"
              name="email"
              borderRadius="lg"
              height="3rem"
              onChange={handleChange}
              _focus={{
                borderColor: "pink.500",
                boxShadow: "0 0 0 0.25rem #f8bbd0",
              }}
              _active={{
                borderColor: "pink.500",
              }}

            />
            <Button borderRadius="lg" height="3rem" type="submit" variantColor="pink">{"Subscribe"}</Button>
          </Stack>
          {
            message &&
            <Box
              width="100%"
              p="0.75rem"
              mt="0.75rem"
              display={message ? "block" : "none"}
              bg={hasError ? "#ffd4d9" : "#d2ffe4"}
              color={hasError ? "#7b000c" : "#007b31"}
              borderRadius="md"
              lineHeight="dense"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          }
        </form>
      </Box>

      <Stack isInline>
        <Checkbox
          isRequired
          alignItems="top"
          pt="0.25rem"
          variantColor="pink"
          borderColor="gray.400"
          name="gdpr-confirmation"
          onChange={handleOptIn}
          _focus={{
            borderColor: "pink.500",
            boxShadow: "0 0 0 0.25rem #f8bbd0",
          }}
          _active={{
            borderColor: "pink.500",
          }}
        />
        <Text>{"Send me updates about z creative labs products"}</Text>
      </Stack>
      
    </Stack>
  )
}

export default Newsletter