import React from "react"
import { Box } from "@chakra-ui/core"

import SEO from "../components/SEO"
import Header from "../components/Header"
import SvgWrapper from "../components/SvgWrapper"
import Viewport from "../components/Viewport"
import Controls from "../components/Controls"
import Footer from "../components/Footer"
import RandomButton from "../components/RandomButton"

const IndexPage = () => {
  return (
    <Box width="100vw" height="100vh" position="relative">
      <SEO title="Blobmaker - Make organic SVG shapes for your next design" />
      <Header />
      <Viewport height={[ "auto", null, null, "60vh" ]} mb={["1.25rem", null, 0]} position="relative">
        <SvgWrapper />
        <Box
          display={["flex", null, null, "none"]}
          position="absolute"
          top="100%"
          zIndex={999}
          mt="-2rem"
        >
          <RandomButton />
        </Box>
      </Viewport>
      <Controls />
      <Footer />
    </Box>
  )
}

export default IndexPage
