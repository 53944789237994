import React, { useState, useEffect, useCallback } from "react"
import {
  Box,
  Input,
  Button,
  SimpleGrid,
} from "@chakra-ui/core"

import { useBlobStore } from "../../utils/store"

const ColorPicker = () => {
  const blobColor = useBlobStore(state => state.color)
  const updateBlobColor = useBlobStore(state => state.updateColor)
  const [localColor, setLocalColor] = useState(blobColor)
  const [ isOpen, setIsOpen ] = useState(false)

  const handleChange = (e) => {
    const value = e.target.value.split("#").join("")
    if (value.length > 6) return
    const match = value.match(/[0-9a-f]+/i)
    if (value && !match) return
    setLocalColor(value ? "#" + match[0] : "#")
    if (value.length === 3) {
      updateBlobColor("#" + value.split("").map(d => d + d).join("").toUpperCase())
    } else if (value.length === 6) {
      updateBlobColor("#" + value.toUpperCase())
    }
  }

  const handleBlur = () => {
    setLocalColor(blobColor)
  }

  const handleMenuChange = (newColor) => {
    updateBlobColor(newColor.hex.toUpperCase())
    setLocalColor(newColor.hex.toUpperCase())
  }

  const escapeHandler = useCallback((e) => {
    if (e.key === "Escape") {
      setIsOpen(false)
    }
  }, [setIsOpen])

  useEffect(() => {
    window.addEventListener("keyup", escapeHandler)
    return () => {
      window.removeEventListener("keyup", escapeHandler)
    }
  }, [escapeHandler])

  return (
    <Box position="relative">
      <Box
        position="fixed"
        top="0"
        left="0"
        bottom="0"
        right="0"
        bg="transparent"
        zIndex={998}
        style={{ display: isOpen ? "block" : "none" }}
        onClick={() => setIsOpen(false)}
      />

      <Input 
        value={localColor}
        variant="filled"
        borderWidth="0.125rem"
        boxShadow="none"
        focusBorderColor="#f06"
        borderRadius="lg"
        onChange={handleChange}
        onClick={() => setIsOpen(true)}
        onFocus={() => setIsOpen(true)}
        onBlur={handleBlur}
        zIndex={999}
        height="3rem"
        fontWeight={700}
        _focus={{
          borderColor: "pink.500",
          boxShadow: "0 0 0 0.25rem #f8bbd0",
        }}
        _active={{
          borderColor: "pink.500",
        }}
      />
      <Box
        position="absolute"
        top="50%"
        right="1rem"
        mt="-0.75rem"
        width="1.75rem"
        height="1.75rem"
        bg={localColor}
        borderRadius="50%"
        zIndex={999}
        style={{ pointerEvents: "none", border: "0.125rem solid #FFF" }}
      />
      <Box
        position="absolute"
        zIndex={999}
        top="100%"
        mt="0.25rem"
        bg="white"
        shadow="large"
        borderRadius="lg"
        style={{ display: isOpen ? "block" : "none" }}
      >
        <SimpleGrid columns={7} spacing="0.5rem" px="1.25rem" py="0.75rem">
          {
            ["#FF0066","#8A3FFC","#fA4D56","#F1C21B","#08BDBA","#0F62FE","#24A148","#A7F0BA","#9EF0F0","#BAE6FF","#D0E2FF","#E8DAFF","#FFD6E8","#F2F4F8"].map(d => {
              return (
                <Button
                  key={d}
                  size="sm"
                  bg={d}
                  boxShadow={d.toLowerCase() === localColor.toLowerCase() ? `0 0 0 0.1875rem rgba(255,255,255,0.6), 0 0 0 0.1875rem ${d}` : "none"}
                  _hover={{
                    bg: d,
                  }}
                  _focus={{
                    bg: d,
                    boxShadow: `0 0 0 0.125rem #FFF, 0 0 0 0.25rem ${d}`,
                  }}
                  _active={{
                    bg: d,
                    boxShadow: `0 0 0 ${d}`,
                  }}
                  onClick={() => handleMenuChange({ hex: d })}
                />
              )
            })
          }
        </SimpleGrid>
      </Box>
      
    </Box>
      
  )
}

export default ColorPicker