import React from "react"
import { Flex } from "@chakra-ui/core"

const Viewport = props => {
  return (
    <Flex 
      width="100vw"
      justifyContent="center"
      alignItems="center"
      px={["1.25rem", null, "3rem"]}
      overflow="visible"
      {...props}
    />
  )
}


export default Viewport