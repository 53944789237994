import React from "react"
import { Box } from "@chakra-ui/core"
import { Spring, animated } from "react-spring/renderprops"

import { useBlobStore } from "../../utils/store"

const SvgWrapper = () => {
  const prevData = useBlobStore(state => state.prevData)
  const data = useBlobStore(state => state.data)
  const prevShape = useBlobStore(state => state.prevShape)
  const shape = useBlobStore(state => state.shape)
  const color = useBlobStore(state => state.color)

  const shouldBypass = data.length !== prevData.length

  return (
    <Box 
      bg="white"
      display="block"
      width="100%"
      maxWidth="30rem"
      height="30rem"
      border="0.125rem"
      borderColor="gray.200"
      backgroundColor="transparent"
      borderStyle="dashed"
      mt={["-4rem", null, null, "-5rem"]}
      as="svg"
      viewBox="0 0 200 200"
      zIndex={-1}
    >
      <g transform="translate(100 100)">
        {
          shouldBypass
            ? (
              <path d={shape} fill={color} />
            ) : (
              <Spring native from={{ d: prevShape }} to={{ d: shape, c: color }} config={{ tension: 200, friction: 12 }}>
                {({ d, c }) => {
                  return <animated.path fill={c} d={d} />
                }}
              </Spring>
            )
        }
      </g>
    </Box>
  )
}

export default SvgWrapper