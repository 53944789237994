import React, { useRef } from "react"
import { 
  useDisclosure,
  Flex,
  Box,
  Stack,
  Icon,
  IconButton,
  Button,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SimpleGrid,
} from "@chakra-ui/core"

import ColorPicker from "../ColorPicker"
import RandomButton from "../RandomButton"
import CodeModal from "../CodeModal"
import { useBlobStore } from "../../utils/store"
import fileDownload from "js-file-download"

const ColorControl = () => {
  return (
    <Box
      pr={[0, null, null, null, "1.5rem"]}
      flex="none"
      py={["1.5rem", null, null, 0]}
      width={["100%", null, null, "auto"]}
      >
      <Box position="relative" maxWidth={["auto", null, null, "11.5rem"]}>
        <ColorPicker />
      </Box>
    </Box>
  )
}

const ComplexityControl = () => {
  const saved = useRef(5)
  const complexity = useBlobStore(state => state.complexity)
  const updateComplexity = useBlobStore(state => state.updateComplexity)
  return (
    <Box px={[0, null, null, "1.5rem"]} py={["1.5rem", null, null, 0]} flex="1 1 auto" width={["100%", null, null, "auto"]}>
      <Stack isInline spacing="1rem">
        <Icon name="complexityStart" width="2rem" height="2rem" />
        <Slider
          color="gray.100"
          min={3}
          max={12}
          value={complexity}
          onChange={val => {
            if (val === saved.current) return
            saved.current = val
            updateComplexity(val)
          }}
        >
          <SliderTrack />
          <SliderFilledTrack />
          <SliderThumb
            size="2rem"
            ml="-0.5rem"
            bg="#f06"
            borderColor="white"
            borderWidth="0.125rem"
            boxShadow="none"
          />
        </Slider>
        <Icon name="complexityEnd" width="2rem" height="2rem" />
      </Stack>
    </Box>
  )
}

const ContrastControl = () => {
  const saved = useRef(4)
  const contrast = useBlobStore(state => state.contrast)
  const updateContrast = useBlobStore(state => state.updateContrast)

  return (
    <Box px={[0, null, null, "1.5rem"]} py={["1.5rem", null, null, 0]} flex="1 1 auto" width={["100%", null, null, "auto"]}>
      <Stack isInline spacing="1rem">
        <Icon name="contrastStart" width="2rem" height="2rem" />
        <Slider
          color="gray.100"
          min={1}
          max={8}
          value={contrast}
          onChange={val => {
            if (val === saved.current) return
            saved.current = val
            updateContrast(val)
          }}
        >
          <SliderTrack />
          <SliderFilledTrack />
          <SliderThumb
            size="2rem"
            ml="-0.5rem"
            bg="#f06"
            borderColor="white"
            borderWidth="0.125rem"
            boxShadow="none"
          />
        </Slider>
        <Icon name="contrastEnd" width="2rem" height="2rem" />
      </Stack>
    </Box>
  )
}

const getSVGContent = (pathString, color) => `<?xml version="1.0" standalone="no"?>
<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
  <path fill="${color}" d="${pathString}" transform="translate(100 100)" />
</svg>
`

const Controls = () => {
  const shape = useBlobStore(state => state.shape)
  const color = useBlobStore(state => state.color)
  const { isOpen, onOpen, onClose } = useDisclosure()
  const btnRef = useRef()

  const handleDownload = () => {
    fileDownload(getSVGContent(shape, color), "blob.svg")
  }
  
  return (
   <SimpleGrid
    position="relative"
    px={["0", null, "1.25rem", "3rem"]}
    justifyContent="center"
    alignItems="flex-start"
    flexWrap="wrap"
    height="auto"
    columns={[1, null, null, null, 12]}
    spacing="2.5rem"
    >
      <Flex
        width="100%"
        flexWrap="wrap"
        bg="white"
        justifyContent="space-between"
        alignItems="center"
        py="1.5rem"
        px="1.25rem"
        borderRadius="lg"
        boxShadow={["none", null, null, "large"]}
        gridColumn={["span 1", null, null, null, "2 / span 10"]}

      >
        <ColorControl />
        <ComplexityControl />
        <ContrastControl />
        <Stack display={["block", null, null, "none"]} isInline spacing="0.5rem" flex="none" alignItems="center" margin="0 auto">
          <Button
            borderRadius="lg"
            variant="outline"
            variantColor="pink"
            borderWidth="0.125rem"
            onClick={handleDownload}
          >
              {"Download"}
          </Button>
          <Button
            onClick={onOpen}
            ref={btnRef}
            borderRadius="lg"
            variant="outline"
            variantColor="pink"
            borderWidth="0.125rem"
          >
            {"Copy code"}
          </Button>
        </Stack>

        <Stack display={["none", null, null, "block"]} isInline spacing="0.75rem" flex="none" alignItems="center" margin="0 auto">
          <IconButton 
            borderRadius="50%"
            size="lg"
            icon="download"
            variant="outline"
            variantColor="pink"
            borderWidth="0.125rem"
            onClick={handleDownload}
          />
          <IconButton
            onClick={onOpen}
            ref={btnRef}
            isRound
            size="lg"
            icon="code"
            variant="outline"
            variantColor="pink"
            borderWidth="0.125rem"
          />

          <CodeModal isOpen={isOpen} btnRef={btnRef} onClose={onClose} />
          
          <RandomButton />
        </Stack>
        
      </Flex>
   </SimpleGrid>
  )
}


export default Controls