import React from "react"
import { Heading, Text, Box, Link, Image, Icon, SimpleGrid, Stack } from "@chakra-ui/core"

import Newsletter from "../Newsletter"
import colors from "../../utils/colors"

import getWavesLogo from "../../images/get-waves-logo.svg"
import geoQuizLogo from "../../images/geo-quiz-logo.svg"

const Footer = () => {
  const currentYear = new Date().getFullYear()
  return (
    <>
      <Box as="svg" display={["none", null, null, "block"]} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none" style={{ width: "100%", height: "18rem", marginTop: "-12rem" }}>
        <path
          fill={colors.gray[100]}
          fillOpacity="1"
          d="M0,64L60,58.7C120,53,240,43,360,69.3C480,96,600,160,720,186.7C840,213,960,203,1080,186.7C1200,171,1320,149,1380,138.7L1440,128L1440,320L1380,320C1320,320,1200,320,1080,320C960,320,840,320,720,320C600,320,480,320,360,320C240,320,120,320,60,320L0,320Z"
        />
      </Box>
      <SimpleGrid px={["1.25rem", null, "3rem"]} pb={["1.25rem", null, "3rem"]} pt={["3rem", null, "4rem", "3rem"]} bg="gray.100" columns={[1, null, 12]} spacing="2.5rem">
        <Text gridColumn={["span 1", null, "1 / span 8", "2 / span 7"]} fontSize={["md", null, "lg", "xl"]} lineHeight="1.5">
          {"Blobmaker is a free generative design tool made with 💕 by "}
          <Link href="https://www.zcreativelabs.com/" color="pink.500">{"z creative labs"}</Link>
          { ", to help you quickly create random, unique, and organic-looking SVG shapes." }
        </Text>
        <Box gridColumn={["span 1", null, "1 / span 8", "2 / span 7"]}>
          <a href="https://www.producthunt.com/posts/blobmaker?utm_source=badge-top-post-badge&utm_medium=badge&utm_souce=badge-blobmaker" style={{ display: "inline-block" }}>
            <img src="https://api.producthunt.com/widgets/embed-image/v1/top-post-badge.svg?post_id=142149&theme=dark&period=daily" alt="Blobmaker - Create organic svg shapes in just a few seconds | Product Hunt Embed" style={{width: "250px", height: "54px"}} width="250px" height="54px" />
          </a>
        </Box>
        <Stack spacing="1.5rem" gridColumn={["span 1", null, "1 / span 8", "2 / span 7"]}>
          <Text fontSize={["md", null, "lg"]} lineHeight="1.5">
            { "From landing pages to illustrations, " }
            <Link href="https://medium.com/@usonesinbetween/2017-the-year-of-the-blob-a3d899c9b071" color="pink.500">
              { "blobs are everywhere" }
            </Link>
            { "! Creating smooth, organic-looking shapes can be difficult, especially when you need many different ones. Blobmaker makes it easy to create unique blob shapes based on random data." }
          </Text>
          <Text fontSize={["md", null, "lg"]} lineHeight="1.5">
            { "Modify the complexity (number of points) and contrast (difference between points) to create different types of organic svg shapes. Press that dice button until you find a blob you like and download it as an SVG or copy the code directly to your clipboard." }
          </Text>
        </Stack>
      </SimpleGrid>
      <SimpleGrid p={["1.25rem", null, "3rem"]} bg="gray.100" columns={[1, null, 12]} spacing="2.5rem">
        <Box gridColumn={["span 1", null, "1 / span 8", "2 / span 4"]}>
          <Newsletter />
        </Box>
        <Stack gridColumn={["span 1", null, "1 / span 8", "span 3"]} spacing="1.75rem">
          <Heading as="h2" fontSize="xl">{"More products"}</Heading>
          <Stack isInline spacing="0.75rem">
            <Image src={getWavesLogo} w="3rem" h="3rem"/>
            <Text>
              {"If you like SVG shape generators, try "}
              <Link href="https://getwaves.io/" color="pink.500">{"getwaves.io"}</Link>
              { ", and make some cool wave transitions for your web pages." }
            </Text>
          </Stack>
          <Stack isInline spacing="0.75rem">
            <Image src={geoQuizLogo} w="3rem" h="3rem"/>
            <Text>{"Have a minute? Challenge yourself with our fun geo quiz! "}
            <Link href="https://geography.games/europe-quiz/" color="pink.500">
              {"Start playing"}
              <Icon name="arrow-forward" ml="0.25rem"/>
            </Link>
            </Text>
          </Stack>
        </Stack>
        <Stack gridColumn={["span 1", null, "1 / span 8", "span 3"]} spacing="1.25rem">
          
          <Heading as="h2" fontSize="xl">{"Contact"}</Heading>
          <Text>
            { "Are you looking for a creative code+design team for your next project? Let's chat!" }<br />
            <Link href="mailto:hello@zcreativelabs.com" color="pink.500">{"hello@zcreativelabs.com"}</Link>
          </Text>
        </Stack> 
      </SimpleGrid>
      <Box bg="gray.100">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320" preserveAspectRatio="none" style={{ marginTop: "-2.5rem", width: "100%", height: "12rem", display: "block" }}>
          <path
            fill="#FF0066"
            d="M0,224L40,218.7C80,213,160,203,240,213.3C320,224,400,256,480,240C560,224,640,160,720,144C800,128,880,160,960,149.3C1040,139,1120,85,1200,96C1280,107,1360,181,1400,218.7L1440,256L1440,320L1400,320C1360,320,1280,320,1200,320C1120,320,1040,320,960,320C880,320,800,320,720,320C640,320,560,320,480,320C400,320,320,320,240,320C160,320,80,320,40,320L0,320Z"
          />
        </svg>
      </Box>
      <Box bg="#FF0066">
        <Text color="white" textAlign="center" py="1.5rem">
          &copy;{` ${currentYear} `}
            <Link href="https://www.zcreativelabs.com/">
              { "z creative labs" }
            </Link>
        </Text>
      </Box>

    </>
  )
}


export default Footer