import React from "react"
import { Box, Button, Icon, Link } from "@chakra-ui/core"

const Share = () => {
    const twitterRoot = "http://twitter.com/intent/tweet"
    const encodedShareText = encodeURIComponent("Make organic #SVG shapes with Blobmaker by @zcreativelabs")
    const shareUrl = `${twitterRoot}?url=https://www.blobmaker.app&text=${encodedShareText}&original_referer=https://www.blobmaker.app`
    return (
      <Button href={shareUrl} as={Link} bg="white" color="gray.600" textDecoration="none !important">
        {"Share"}
        <Box ml="1rem">
          <Icon name="twitter" />
        </Box>
      </Button>
  )
}

export default Share