
import React, { useRef } from "react"
import { 
  Heading,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  SlideIn,
  useClipboard,
  Box,
  Button,
} from "@chakra-ui/core"

import { useBlobStore } from "../../utils/store"

const codeSnippet = (pathString, color) => `<svg viewBox="0 0 200 200" xmlns="http://www.w3.org/2000/svg">
  <path fill="${color}" d="${pathString}" transform="translate(100 100)" />
</svg>
`

const CodeModal = ({ isOpen, btnRef, onClose }) => {
  const shape = useBlobStore(state => state.shape)
  const color = useBlobStore(state => state.color)

  const initialRef = useRef(null)

  const snippet = codeSnippet(shape, color)
  const { onCopy, hasCopied } = useClipboard(snippet)

  return (
    <SlideIn in={isOpen}>
      {styles => (
        <Modal initialFocusRef={initialRef} finalFocusRef={btnRef} onClose={onClose} isOpen={true} size={["calc(100% - 1.25rem)", null, "2xl"]}>
          <ModalOverlay opacity={styles.opacity} />
          <ModalContent borderRadius="lg">
            <ModalHeader>
              <Heading as="h3" fontSize="lg">
                {"Copy SVG code"}
              </Heading>
            </ModalHeader>
            <ModalCloseButton borderRadius="100%" />
            <ModalBody overflow="hidden">
              <Box as="pre" overflow="scroll" bg="gray.800" color="white" borderRadius="lg" px="1rem" py="1.25rem">
                {snippet}
              </Box>
              <Box py="1.25rem">
                <Button ref={initialRef} borderRadius="lg" onClick={onCopy} variantColor="pink" leftIcon={hasCopied ? "check" : "copy"}>
                  {
                    hasCopied
                    ? "Copied SVG to clipboard"
                    : "Copy SVG to clipboard"
                  }
                </Button>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
      )}
    </SlideIn>
  )
}

export default CodeModal
