import React from "react"
import { Text, Flex, Image, Link, Stack } from "@chakra-ui/core"
import Share from "../Share"

import logo from "../../images/blobmaker-logo.svg"
import HaikeiBanner from "../HaikeiBanner"

const Header = () => {
  return (
    <>
      <HaikeiBanner />
      <Flex
        px={["2rem", null, null, "3rem"]}
        pt={["2rem", null, null, "1rem"]}
        justifyContent="space-between"
        alignItems="center"
      >
        <Stack isInline alignItems="center">
          <Image src={logo} height={["3rem", null, "4.5rem"]} />
          <Text fontWeight={600}>
            {"By "}
            <Link href="https://www.zcreativelabs.com/" color="pink.500">
              {"z creative labs"}
            </Link>
          </Text>
        </Stack>
        <Share />
      </Flex>
    </>
  )
}

export default Header