
import create from "zustand"
import { radialLine, curveBasisClosed } from "d3-shape"
import { range } from "d3-array"
import { scaleLinear } from "d3-scale"

function roundPath(path, precision = 0.1) {
  if (!path) return
  const query = /[\d.-][\d.e-]*/g
  return path.replace(query, n => Math.round(n * (1/precision)) / (1/precision))
}

const generateBlobShape = (data) => {
  const shapeGenerator = radialLine()
    .angle((d, i) => (i / data.length) * 2 * Math.PI)
    .curve(curveBasisClosed)
    .radius(d => d)
  return shapeGenerator(data.map(d => Math.abs(d)))
}

const generateData = (complexity, contrast) => {
  const scale = scaleLinear()
    .domain([0, 1])
    .range([100 - (100 / 8 * contrast - 0.01), 100])
  return range(complexity).map(() => scale(Math.random()))
}

const initialData = generateData(5, 4)

export const [useBlobStore] = create(set => ({
  contrast: 4,
  complexity: 5,
  color: "#FF0066",
  prevData: initialData,
  data: initialData,
  shape: roundPath(generateBlobShape(initialData) + "Z"),
  prevShape: roundPath(generateBlobShape(initialData) + "Z"),

  updateContrast: contrast => {
    set(state => {
      const data = generateData(state.complexity, contrast)
      const shape = roundPath(generateBlobShape(data) + "Z")
      const prevShape = roundPath(generateBlobShape(state.data) + "Z")
      return { prevData: state.data, prevShape, shape, data, contrast }
    })
  },
  updateComplexity: complexity => {
    set(state => {
      const data = generateData(complexity, state.contrast)
      const shape = roundPath(generateBlobShape(data) + "Z")
      const prevShape = roundPath(generateBlobShape(state.data) + "Z")
      return { prevData: state.data, prevShape, shape, data, complexity }
    })
  },
  updateColor: color => {
    set({ color })
  },
  updateData: () => {
    set(state => {
      const data = generateData(state.complexity, state.contrast)
      const shape = roundPath(generateBlobShape(data) + "Z")
      const prevShape = roundPath(generateBlobShape(state.data) + "Z")
      return { prevData: state.data, prevShape, shape, data }
    })
  },
}))
